import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { format } from "date-fns";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="search"
      placeholder="Search by Email, Name, Phone"
      aria-label="Search Input"
      defaultValue={filterText}
      onChange={onFilter}
      className="w-25 form-control float-left"
      autoComplete="off"
    />
    {/* <button type="button" className="btn bg-black text-white mr-5" onClick={onClear} disabled>
     <MdSearch/>
    </button> */}
  </>
);

const NourishRegUserList = () => {
  const nourishmedusatoken = JSON.parse(localStorage.getItem("NStoken"));
  const [UserData, setUserData] = useState();
  const [pending, setPending] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const filterProperties = ["email", "name", "id", "phone"];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <div className="SubFilterheader w-100 d-inline border p-3">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          className=""
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${nourishmedusatoken}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_Nourish_URL}admin/customers?limit=100000000`,
          requestOptions
        );
        const result = await response.json();
        setUserData(result);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  // console.log(JSON.stringify(UserData?.customers[0]?.metadata !== null ? UserData?.customers[0]?.metadata?.gender : "website"))
  const columns = [
    {
      name: "S. No.",
      selector: (row) => row.i,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => format(new Date(row.created_at), "MM/dd/yyyy"),
      sortable: true,
    },
    {
      name: "Channel Type",
      selector: (row) => row?.chaneltype,
      sortable: true,
    },
  
    {
      name: "Name",
      selector: (row) => row?.name === null ? "" : row?.name,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: false,
      grow: 2,
    },
    {
      name: "Phone",
      // grow: 2,
      selector: (row) => row?.phone,
    },
    // {
    //   name: "Address",
    //   selector: (row) => row.address,
    // },
    // {
    //   name: "City",
    //   selector: (row) => row.city,
    // }
  ];

  const Tabledata = UserData?.customers
    .filter((item) =>
      filterProperties.some(
        (property) =>
          new Date(item.created_at) > new Date("11/02/2023 01:29:37") &&
          item[property] &&
          item[property].toLowerCase().includes(filterText.toLowerCase())
      )
    )
    .map((item, index) => ({
      i: index + 1,
      created_at: item?.created_at,
      name: item?.first_name !== null ? (item.first_name + " " + item.last_name ) : "NA",
      email: item?.email,
      phone: item?.phone,
      chaneltype: item.metadata !== null ? (item?.metadata?.gender !== undefined ? "App" : "website") : "website",
    }));

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            columns={columns}
            data={Tabledata}
            defaultSortAsc={true}
            progressPending={pending}
            paginationTotalRows={Tabledata?.length}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            pagination
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
              rangeSeparatorText: "of",
              selectAllRowsItem: true,
              selectAllRowsItemText: "All",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NourishRegUserList;
